@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.article {
    width: 300px;

    &__img {
        height: 200px;
        border-radius: 16px;
    }

    &__name {
        height: 48px;
        overflow: hidden;
    }

    &__title {
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }
}

.tag {
    padding: 4px 16px;
    border-radius: 16px;
    height: 32px;
    white-space: nowrap;
}

:deep(.q-scrollarea__container) {
    overflow: auto;
}

:deep(.q-scrollarea__content) {
    display: flex;
    align-items: center;
}

.next,
.prev {
    position: absolute;
    top: 50%;
    z-index: 10;
    width: 40px;
    background: $secondary;

    @media screen and (max-width: $breakpoint-mobile) {
        display: none;
    }
}

.next {
    right: 0;
    transform: translateX(50%) translateY(-50%);

    @media screen and (max-width: $breakpoint-mobile) {
        transform: translateX(30%);
    }
}

.prev {
    left: 0;
    transform: translateX(-50%) translateY(-50%);

    @media screen and (max-width: $breakpoint-mobile) {
        transform: translateX(-30%);
    }
}

.next > .q-btn,
.prev > .q-btn {
    width: 38px;
    height: 38px;
    display: block;
    background: $secondary;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 1280px) {
        width: 28px;
        height: 28px;
    }
}

.q-icon {
    font-size: 24px;
    @media (max-width: 1280px) {
        font-size: 8px;
    }
}

