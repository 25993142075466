$primary: #242424;
$secondary: #FFFFFF;
$accent: #F2F2F2;

$dark: #444444;
$gray: #939393;
$light-gray: #e5e5e5;
$medium-gray: #acacac;

$positive: #0CA551;
$negative: #F13613;
$info: #F59B14;
$warning: #F2C037;
$stock: #FFC876;

$light-blue: #a1d2dd;
$light-border-input: #e6e6e6;

$app-text-primaty: #242424;
$app-text-secondary: #FFFFFF;
$app-text-accent: #4AB569;
$app-color-violet: #8C82E4;
$app-color-yellow: #FFC876;
$app-color-liam: #CDDE67;
$app-color-orange: #FEA47E;
$app-color-pink: #FFADA2;
$app-color-blue: #A5C8E8;
$app-color-heavenly: #E6FBFF;
$app-color-gray: #F2F2F2;
$app-color-gray-gray: #D2D2D2;

$app-lg-line-height: 32px;
$app-md-line-height: 19px;
$app-sm-line-height: 16px;

$app-lg-font-size: 20px;
$app-md-font-size: 16px;
$app-sm-font-size: 12px;

$size-8: 5px;

$text-weights: (light: 300, regular: 400, semi_medium: 500, medium: 600, bold: 700, extra_bold: 800) !default;
$generic-border-radius: 10px !default;
$app-color-background: #F8F8F8;
$app-gradient: linear-gradient(95deg, #FDFFA8 -20%, #7DD77C 40%, #00B5BA 140%);
$app-button-gradient: linear-gradient(95deg, #FDFFA8 -130%, #27BD24 -100%, #00B5BA 160%);
$app-border: 1px solid rgba(0, 0, 0, 0.12);
$mobile-border-radius: 16px;

$button-border-radius: 10px !default;

$shadow-color: #000 !default;

$elevation-umbra: rgba($shadow-color, .20) !default;
$elevation-penumbra: rgba($shadow-color, .14) !default;
$elevation-ambient: rgba($shadow-color, .12) !default;

$menu-box-shadow: 0 11px 15px -7px $elevation-umbra, 0 24px 38px 3px $elevation-penumbra, 0 9px 46px 8px $elevation-ambient !default;

$lime: linear-gradient(95deg, #FDFFA8 -50%, #7DD77C 30%, #00B5BA 140%) !default;
$grey-1: rgba($primary, 0.5);
$grey-2: $app-color-background;
$grey-3: #F8F8F8;
$grey-4: rgba($primary, 0.4);
$grey-5: #FBFAFA;
$grey-6: rgba($primary, 0.1);
$grey-7: #D2D2D2;
$grey-8: #bdbdbd;
$grey-9: #ACACAC;
$grey-10: #fafafa;
$grey-11: #333333;
$grey-12: #828282;
$grey-13: #e6e6e6;
$grey-14: #e0e0e0;
$grey-15: #c4c4c4;
$grey-16: #e2e2e2;
$grey-17: #f2f2f2;
$grey-18: #EBEBEB;
$grey-19: #808080;
$grey-20: #f5f5f5;


$green-1: #E7F7E8;
$green-2: #A8E186;
$green-3: #7CC290;
$green-4: #f1fef2;
$green-5: #92BFA3;
$green-6: #0CA551;
$green-7: #E3F1E9;
$green-8: #A3E3A9;
$green-11: #DEF1D9;
$green-13: #D3F2DC;
$green-14: #22BD77;
$green-15: #A3E3A9;


$lime-1: #5AA600;
$lime-2: #5DB65D;
$lime-3: #A5E5D4;
$lime-4: #B8E4D7;

$yellow-1: #BBD023;
$yellow-2: #EBE631;
$yellow-3: #FFD1A6;
$yellow-4: #F99F19;
$yellow-5: #F6D5B7;

$deep-purple-1: #8C82E4;

$blue-1: #B1CAF4;
$blue-2: #2C84A4;
$blue-3: #DDEFED;
$blue-4: #448D8B;
$blue-8: #7189DF;
$blue-9: #4673AA;
$blue-10: #03BFD9;
$blue-11: #267799;
$blue-12: #2D8BB2;
$blue-13: #1462A9;
$blue-14: #92BCE4;
$blue-15: #E8F1FA;

$light-blue-2: #EEF6FE;
$light-blue-3: #96CAD9;
$light-blue-4: #D9EFF8;
$light-blue-5: #C3DBE3;

$red-1: #E52628;
$red-2: #FFC2BE;
$red-3: #D80F16;
$red-4: #DF7E71;
$red-5: #FFC5E5;


$main-transition: 0.7s all;

$breakpoint-mobile: 599px;

$deep-purple-6: #5E3BA5;

$cyan-1: #E1F7FF;
$cyan-2: #2C84A4;

$purple-1: #C6BAF9;
$purple-2: #5E3BA5;
$purple-3: #CFC5F1;
$purple-4: #DFC7F2;
$purple-5: #6E12E2;
$purple-6: #7C21EF;

$white-b: #F8FAFB;

